var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 py-0"},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogImage),callback:function ($$v) {_vm.dialogImage=$$v},expression:"dialogImage"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.cancelImage()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('add_edit_avatar')))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.saveImage()}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1),_c('v-card-text',[_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"align":"center","justify":"center"}},[(!_vm.edit)?_c('v-col',{attrs:{"cols":"12"}},[(!_vm.edit)?_c('v-file-input',{attrs:{"accept":".png,.jpg,.jpeg","show-size":1024,"label":_vm.$t('choose_image_file'),"color":"primary"},on:{"change":_vm.setupCropper},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}}):_vm._e()],1):_vm._e()],1),_c('v-row',[(!!_vm.objectUrl)?_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('div',{style:('width: '+_vm.windowSize.width+'px; height: '+_vm.windowSize.height+'px;')},[_c('img',{ref:"sourceImage",attrs:{"src":_vm.objectUrl}})])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","title":_vm.$t('format_reset')},on:{"click":_vm.resetCropper}},on),[_c('v-icon',[_vm._v("mdi-aspect-ratio")])],1)]}}],null,false,380637868)},[_c('span',[_vm._v(_vm._s(_vm.$t('format_reset')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","title":_vm.$t('rotate_by_45_to_left')},on:{"click":_vm.rotateLeft}},on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,false,805842987)},[_c('span',[_vm._v(_vm._s(_vm.$t('rotate_by_45_to_left')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","title":_vm.$t('rotate_by_45_to_right')},on:{"click":_vm.rotateRight}},on),[_c('v-icon',[_vm._v("mdi-rotate-right")])],1)]}}],null,false,1512058992)},[_c('span',[_vm._v(_vm._s(_vm.$t('rotate_by_45_to_right')))])])],1)])],1)],1):_vm._e(),(_vm.previewCropped && _vm.objectUrl)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center justify-sm-start",attrs:{"cols":"6","sm":"12"}},[_c('v-avatar',{staticClass:"border",attrs:{"tile":"","color":"white","size":"100"}},[_c('img',{staticClass:"image-preview px-3 py-3",attrs:{"src":_vm.previewCropped}})])],1),_c('v-col',{staticClass:"d-flex justify-center justify-sm-start",attrs:{"cols":"6","sm":"12"}},[_c('v-avatar',{staticClass:"border",attrs:{"color":"white","size":"100"}},[_c('img',{staticClass:"image-preview px-3 py-3",attrs:{"src":_vm.previewCropped}})])],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-card',{attrs:{"outlined":"","elevation":"3"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pa-2"},[(_vm.imageSrc)?_c('v-img',{attrs:{"src":_vm.imageSrc,"contain":""}}):_vm._e(),(_vm.imageSrc)?_c('img',{ref:"image",staticStyle:{"display":"none"},attrs:{"src":_vm.imageSrc}}):_vm._e(),(!_vm.imageSrc)?_c('v-img',{attrs:{"src":_vm.defaultAvatar,"contain":""}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"d-flex flex-row pa-0",attrs:{"justify":"space-between center"}},[(_vm.imageSrc)?_c('v-container',{staticClass:"pt-0 pb-1 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editImage()}}},on),[_c('v-icon',[_vm._v("mdi-image-edit")])],1)]}}],null,false,1982636843)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])],1):_vm._e(),(!_vm.imageSrc)?_c('v-container',{staticClass:"pt-0 pb-1 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.addImage()}}},on),[_c('v-icon',[_vm._v("mdi-image-plus")])],1)]}}],null,false,50249584)},[_c('span',[_vm._v(_vm._s(_vm.$t('add')))])])],1):_vm._e(),(_vm.imageSrc)?_c('v-container',{staticClass:"pt-0 pb-1 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.removeImage()}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,4079638396)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }