<template>
    <v-container fluid  >
        <v-form @submit.prevent="saveProfile">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col>
                                <h3 class="headline pb-2" color="black">{{ $t('client_edit') }}</h3>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-btn
                                    right
                                    icon
                                    color="primary"
                                    :title="$t('notifications')"
                                    @click="openNotifications()"
                                >
                                    <v-icon>mdi-bell-ring-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2" sm="3" class="py-0 mt-3 px-sm-6 text-center">
                                <make-avatar v-model="photo" :aspect-ratio="(1/1)"/>
                            </v-col>
                            <v-col class="pt-10  mt-0" cols="12"  md="10" sm="9">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="lname" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="lname" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('client_last_name')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="fname" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="fname" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('client_first_name')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="mname" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="mname" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('client_middle_name')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="iin" rules="integer|min:12|max:12"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="iin" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-badge-account-horizontal-outline"
                                                          :label="$t('client_iin')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="phone" rules="phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="phoneMask"
                                                          :error-messages="errors" disabled
                                                          prepend-icon="mdi-phone"
                                                          :label="$t('phone')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          readonly
                                            >
                                            </v-text-field>
                                        </ValidationProvider>

                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="email" rules="email"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email" :error-messages="errors"
                                                          :disabled="loading" :error="!valid"
                                                          prepend-icon="mdi-email" :label="$t('email')"
                                                          outlined
                                                          rounded
                                                          dense
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="country" rules="required" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="country" :items="countryItems"
                                                            :error="!valid" :error-messages="errors"
                                                            item-text="name" item-value="uuid" prepend-icon="mdi-web"
                                                            :loading="loadingCountries" :disabled="loading"
                                                            :label="$t('country')" @click:clear="clearCountries"

                                                            outlined
                                                            rounded
                                                            dense
                                                            color="primary" return-object clearable >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider  ref="city" rules="required" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="city" :items="cityItems"
                                                            :error="!valid" :error-messages="errors"
                                                            item-text="name" item-value="uuid" prepend-icon="mdi-city"
                                                            :loading="loadingCities" :disabled="loading || !country"


                                                            :label="$t('city')" @click:clear="clearCities"
                                                            outlined
                                                            rounded
                                                            dense
                                                            color="primary" return-object clearable autocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>

        <v-dialog v-if="dialogNotifications" v-model="dialogNotifications" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Привязанные устройства</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="closeNotifications()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="mb-10">
                    <v-row class="mt-2">
                        <v-col class="text-center">
                        На этих устройствах выполнен вход и на них приходят все уведомления. Если устройство в списке вам не знакомо, удалите его.
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>


                    <v-row  v-for="(item, index) in deviceItems" :key="index" >
                        <v-col>
                            {{ item.user_agent }}
                        </v-col>
                        <v-col cols="2" class="text-right mt-2" >
                            <template  class="text-right">
                                <v-tooltip>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="mr-3" @click="deleteNotification(item.id)" :title="$t('delete')"
                                        >
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span>{{$t('delete')}}</span>
                                </v-tooltip>
                            </template>
                        </v-col>
                    </v-row>






                </v-card-text>
                <v-card-actions class="px-0 mx-0">
                    <v-row class="px-0 mx-0">
                        <v-col class="text-center px-0 mx-0">
                    <v-btn
                        color="primary "
                        small
                        @click="listenChannelFCM2()"

                    >
                        Получать уведомления на это устройство
                    </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import MakeAvatar from '../components/MakeAvatar.vue'

export default {
    name: 'ProfileForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogNotifications: false,
            heading: null,
            progress: 0,
            create: false,
            phoneRaw: null,
            int_phone: null,
            uuid: null,
            fname: null,
            lname: null,
            mname: null,
            email: null,
            iin: null,
            active: false,
            photo: null,


            loading: false,
            loadingCities: false,
            loadingCountries: false,
            country: null,
            countryItems: [],
            city: null,
            cityItems: [],
            allCityItems: [],
            deviceItems: [],


        }
    },
    computed: {
        ...mapGetters(['lang', 'phoneMask', 'phoneIntMask', 'lang']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
    },
    watch: {
        country: debounce(function (val) {
            this.getCities(val)
        }, 500),
     },
    mounted() {
        this.getCountries()
        this.getProfile()
        this.getCityClient()
    },
    methods: {
        ...mapActions(['setUserName', 'setUserPosition', 'setUserAvatar']),
        closeNotifications(){
            this.dialogNotifications = false;
        },
        async openNotifications(){
            var _this = this
            this.loading = true
            let params = {};
            params.filter = 'search';
            await this.$http
                .get("courier/device", {
                    params: params,
                })
                .then(res => {
                    this.deviceItems = res.body.data
                })
                .catch(err => {
                    this.deviceItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_cities'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
            this.dialogNotifications = true;
        },

        clearCountries() {
                this.country = null
                this.city = null
                this.cityItems = []
        },
        clearCities() {
                this.city = null
        },
        async getCities(str) {
            if (str) {
                this.loadingCities = true
                let params = {};
                params.filter = 'search';
                if (this.country) {
                    if (this.country.uuid) {
                        params.country = this.country.uuid;
                    } else {
                        params.country = this.country;
                    }
                }
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("courier/city", {
                        params: params,
                    })
                    .then(res => {

                        this.cityItems = res.body.data


                    })
                    .catch(err => {
                        this.cityItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_cities'))
                    })
                    .finally(end => {
                        this.loadingCities = false
                    })
            }
        },
        async getCountries() {
            this.loadingCountries = true
            let params = {};
            params.filter = 'search';
            if (this.lang) {
                params.language = this.lang
            }
            await this.$http
                .get("courier/country", {
                    params: params,
                })
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.countryItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_countries'))
                })
                .finally(end => {
                    this.loadingCountries = false
                })
        },
        async getProfile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {};
            if (this.lang) {
                params.language = this.lang
            }
            await this.$http
                .get(`courier/profile`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.uuid = res.body.data.uuid
                    this.fname = res.body.data.fname
                    this.lname = res.body.data.lname
                    this.mname = res.body.data.mname
                    this.phoneRaw = res.body.data.phone
                    this.email = res.body.data.email
                    this.iin = res.body.data.iin
                    this.photo = res.body.data.photo

                    let country = res.body.data.country
                    if (country && country.uuid) {
                        this.country = country.uuid
                      //  this.countryItems = [res.body.data.country]
                    }

                    let city = res.body.data.city
                    if (city && city.uuid) {
                        this.city = city.uuid
                        this.allCityItems = [res.body.data.city]
                    } else {
                        this.allCityItems = []
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_client'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveProfile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.lname) {
                formData.append('lname', this.lname)
            }
            if (this.fname) {
                formData.append('fname', this.fname)
            }
            if (this.mname) {
                formData.append('mname', this.mname)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.email) {
                formData.append('email', this.email)
            }
            if (this.iin) {
                formData.append('iin', this.iin)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.country) {
                if (this.country.uuid) {
                    formData.append('country', this.country.uuid)
                } else {
                    formData.append('country', this.country)
                }
            }
            if (this.city) {
                if (this.city.uuid) {
                    formData.append('city', this.city.uuid)
                } else {
                    formData.append('city', this.city)
                }
            }
            if (this.lang) {
                formData.append('language', this.lang)
            }
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }

            // Save
            await this.$http
                .put(`courier/profile/${this.uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('client_has_been_updated'))
                  //  if ((this.$route.params.id && this.$route.params.id == this.$auth.user().id) || (this.$route.name === 'profile' && this.$auth.check())) {
                        this.$auth.fetch()
                        .then(res => {
                            this.setUserName(this.$auth.user().fname)
                            this.setUserPosition(this.$auth.user().position)
                            this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                        })
                    //}
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('client_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async getCityClient(){
            this.city =this.allCityItems
        },
        listenChannelFCM2() {
            if (window.messaging) {
                window.messaging.requestPermission()
                    .then(() => {
                        console.log('Notification permission granted.')
                        window.messaging.getToken().then((token) => {
                            //console.log('New token created: ', token)
                            this.saveNotificationToken2(token)
                            this.setTokenToLocalStorage2(false);
                        })
                    })
                    .catch((err) => {
                        console.log('Unable to get permission to notify.', err)
                        this.setTokenToLocalStorage2(false);
                    })


            }
        },
        saveNotificationToken2(token) {
            var _this = this;
            this.progress = 0
            this.loading = true
            const params = {
                token: token,
                type: 'browser'
            }
            this.$http
                .post("courier/device", params)
                .then((res) => {
                    this.openNotifications()
                    //console.log('Successfully saved notification token!')
                })
                .catch((err) => {
                   // console.log('Error: could not save notification token')
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        setTokenToLocalStorage2(currentToken) {
            window.localStorage.setItem(
                'sentFirebaseMessagingToken',
                currentToken ? currentToken : ''
            )
        },
        async deleteNotification(id) {
            if (confirm(this.$t('delete_device'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`courier/device/${id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('device_has_been_deleted'))
                        this.openNotifications()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('device_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>
